<template>
  <v-card flat title>
    <v-toolbar class="mb-2" color="primary" dark flat>
      <v-icon class="mr-5">mdi-chart-timeline-variant</v-icon>
      <v-toolbar-title>Activities Report</v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <v-card-text class="py-3">
      <v-form ref="form" v-model="valid">
        <v-row>
          <v-col cols="12" sm="4" md="4">
            <auto-complete-users
              label="USER"
              :rules="[v => !!v || 'Required']"
              v-model="userSelected"
              editable
              @edit="sheet = true"
            />
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <date-picker
              v-model="date[0]"
              label="START DATE"
              :rules="[v => !!v || 'Required']"
              @clear="date = [null, null]"
            />
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <date-picker
              v-model="date[1]"
              label="END DATE"
              :minDate="date[0]"
              :disable="!date[0]"
            />
          </v-col>
          <v-col cols="12" sm="2" md="2">
            <v-btn
              block
              color="primary"
              @click="getReport"
              :disabled="!valid || !!errorMsg"
              :loading="loading"
            >
              Submit
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-list v-if="report.length">
        <v-list-item v-for="category in report" :key="category.title">
          <v-list-item-avatar>
            <v-icon class="primary lighten-1" dark>
              mdi-chart-timeline-variant-shimmer
            </v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title v-text="category.title"></v-list-item-title>

            <v-list-item-subtitle
              v-text="category.description"
            ></v-list-item-subtitle>
            <v-divider></v-divider>
          </v-list-item-content>

          <v-list-item-action>
            <h1>{{ category.value }}</h1>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-banner v-if="errorMsg" color="light-blue lighten-5">
        <v-icon slot="icon" color="primary" size="36">
          mdi-alert-circle
        </v-icon>
        {{ errorMsg }}
      </v-banner>
    </v-card-text>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-bottom-sheet
      v-model="sheet"
      persistent
      :fullscreen="full"
      :scrollable="full"
    >
      <new-user ref="newUser" @close="sheet = false" />
    </v-bottom-sheet>
  </v-card>
</template>

<script>
import AutoCompleteUsers from '../../components/appComponents/AutoCompleteUsers.vue';
import DatePicker from '../../components/appComponents/DatePicker.vue';
import { mapActions } from 'vuex';
import { notifyError } from '../../helpers/notifications';
import NewUser from '../users/forms/sections/NewUser.vue';
export default {
  components: { AutoCompleteUsers, DatePicker, NewUser },
  name: 'iccat',
  data: () => ({
    overlay: false,
    userSelected: null,
    date: [null, null],
    valid: false,
    report: [],
    errorMsg: '',
    loading: false,
    sheet: false
  }),
  computed: {
    full() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    userId() {
      return this.userSelected?._id || null;
    }
  },
  watch: {
    userSelected: {
      handler: 'checkEmail'
    },
    sheet: {
      handler: 'initEditUser',
      immediate: true
    }
  },
  methods: {
    ...mapActions('query', ['getActivitiesReport']),
    initEditUser(value) {
      if (value) {
        setTimeout(async () => {
          if (this.$refs.newUser) {
            await this.$refs.newUser.init(this.userSelected?._id);
          }
        }, 100);
      }
    },
    async getReport() {
      this.report = [];
      this.errorMsg = '';

      if (this.valid && this.userSelected?.email) {
        this.loading = true;
        try {
          const response = await this.getActivitiesReport({
            email: this.userSelected.email,
            startDate: this.date[0],
            endDate: this.date[1]
          });

          const { data } = response;

          for (const key of Object.keys(data || {})) {
            this.report.push({
              title: key.split('_').join(' '),
              description: '',
              value: data[key]
            });
          }

          if (!this.report.length) {
            this.errorMsg =
              'No data found for this user in the requested date range';
          }
        } catch {
          notifyError('Something went wrong');
        }

        this.loading = false;
      }
    },
    checkEmail(user) {
      if (!user?.email) {
        this.errorMsg = 'The selected user has not email saved';
      } else {
        this.errorMsg = '';
      }
    }
  }
};
</script>

<style scoped></style>
